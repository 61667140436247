//./utils/txt.js
import React from "react";
import { Link } from 'react-router-dom';

export const dict = {
  fr: {
    Action_errorRemoveAccountOwner: "Seul le propriétaire du compte peut supprimer son compte",
    Action_errorRemoveAccountCollections: "S'il vous plait, supprimez vos collections avant de supprimer le compte",
    Action_errorRemoveAccountDashboards: "S'il vous plait, supprimez vos tableaux avant de supprimer le compte",
    Action_errorDeleteDashboards:  "S'il vous plait, supprimez tous les tableaux avant de supprimer la collection",
    Action_errorDeleteCollectionSeries: "S'il vous plait, supprimez tous les indicateurs avant de supprimer la collection",
    Action_errorDeleteCollectionMembers: "S'il vous plait, supprimez tous les membres avant de supprimer la collection",
    Action_errorDeleteCollectionMemberOwner: "Le propriétaire de la collection ne peut supprimer son compte",
    Action_errorDeleteParentSeries: "S'il vous plait, supprimez d'abord tous les indicateurs avec ce parent",
    Action_errorRemoveDashboardCharts: "S'il vous plait, supprimez tous les graphiques avant de supprimer le tableau",
    Action_errorDeleteCollectionOwner: "S'il vous plait, seul le propriétaire peut supprimer une collection",
    Alert_add: "Ajouter",
    Alert_never: "Pas d'alerte",
    Alert_onUpdateDaily: "Alerte fin de journée",
    Alert_onUpdateWeekly: "Alerte une fois par semaine",
    Alert_onUpdateMonthly: "Alerte une fois par mois",
    Alert_onUpdateDailyThreshold: "Alerte fin de journée avec seuil",
    Alert_remove: "Supprimer",
    Alert_setAlertThreshold: "Définir l'alerte avec seuil",
    Alert_setAlertThreshold_p1: "Recevoir une alerte à la mise à jour:",
    Alert_setAlertThreshold_p2: "si l'indicateur dépasse:",
    Alert_setAlertThreshold_p3: "ou si l'indicateur est inférieur à:",
    App_about: "About",
    App_access: "Accès",
    App_ACCOUNT: "COMPTE",
    App_account: "Compte",
    App_accounts: "Comptes",
    App_accountMain: "Général",
    App_accountMemberships: "Accès",
    App_accountPublisher: "Éditeur - Pour publier et accéder aux indicateurs",
    App_accountType: "Type de compte",
    App_accountTypeHelper: "Ce n'est pas un choix définitif, vous pourrez changer après",
    App_accountUser: "Utilisateur - Pour accéder aux indicateurs",
    App_action: "Action",
    App_addDashboard: "Ajouter le tableau",
    App_addDashboardVerbose: "Voulez-vous ajouter le tableau à vos tableaux personnels?",
    App_addToDashboard: "Ajouter à un tableau",
    App_ALERTS: "ALERTES",
    App_ANALYTICS: "ANALYSE",
    App_analyticsActions: "Actions",
    App_analyticsActionsNone: "Il n'y a pas encore de consultation de cette collection",
    App_analyticsAssetSub: "Indicateur",
    App_analyticsDashboardSub: "Tableau",
    App_analyticsSubscribtions: "Favoris",
    App_analyticsSubsNone: "Il n'y a pas encore de favori sur cette collection",
    App_analyticsdownload: "Téléchargement",
    App_analyticsdownloadPng: "Téléchargement image",
    App_analyticsgetAsset: "Indicateur",
    App_analyticsgetCollection: "Collection",
    App_analyticsgetSerie: "Indicateur",
    App_analyticsgetDashboard: "Tableau",
    App_analyticsgetItem: "Graphique",
    App_appDescription: "Statit vous permet d'organiser vos indicateurs importants et de les partager avec votre équipe ou communauté. Démarrez en quelques minutes",
    App_appTitle: "Statit | Suivre ce qui compte",
    App_askedBy: "Par",
    App_cancel: "Annuler",
    App_channel: "Canal",
    App_CHART: "GRAPHIQUE",
    App_chart: "Graphique",
    App_chartHasBeenSaved: "Le graphique a bien été enregistré",
    App_CHARTS: "GRAPHIQUES",
    App_charts: "Graphiques",
    App_chartBar: "Graphique en barre",
    App_chartFormat: "Format",
    App_chartLine: "Graphique en ligne",
    App_chartLineSeasonal: "Graphique en ligne - Saisonnier",
    App_chartValue: "Graphique en valeur",
    App_color: "Couleur",
    App_COLLECTION: "COLLECTION",
    App_collection: "Collection",
    App_COLLECTIONS: "COLLECTIONS",
    App_collections: "Collections",
    App_collectionAccount: "Compte",
    App_collectionPrivate: "Privée",
    App_collectionPublic: "Public",
    App_comments: "Commentaires",
    App_community: "Communauté",
    App_configuration: "Configuration",
    App_conditions: "Conditions",
    App_createAlert: "Ajouter une alerte",
    App_createNewDashboard: "Ajouter un nouveau tableau",
    App_DASHBOARD: "TABLEAU",
    App_dashboard: "Tableau",
    App_dashboardHasBeenSaved: "Le tableau a été enregistré parmi vos tableaux personnels",
    App_dashboardPersonal: "Tableau personnel",
    App_DASHBOARDS: "TABLEAUX DE BORD",
    App_dashboards: "Tableaux de bord",
    App_data: "Données",
    App_DATA: "DONNÉES",
    App_description: "Description",
    App_DESCRIPTION: "DESCRIPTION",
    App_documentation: "Guide utilisateur",
    App_download: "Télécharger",
    App_downloadData: "Télécharger les indicateurs",
    App_downloadChart: "Télécharger graphique",
    App_edit: "Editer",
    App_email: "Email",
    App_errorDb: "Une erreur a eu lieu. Toutes nos excuses",
    App_errorNetwork: "Le réseau ne semble pas connecté. Etes-vous toujours en ligne?",
    App_errorBoundary: <>Désolé, une erreur a eu lieu. Si cela persiste, écrivez nous s'il vous plait à <a href="mailto:hi@gostatit.com">hi@gostatit.com</a></>,
    App_FAVORITES: "FAVORIS",
    App_favorites: "Favoris",
    App_favorite: "Favori",
    App_file: "Fichier",
    App_fileCsv: "Fichier Csv",
    App_fileXlsx: "Fichier Excel",
    App_filter: "Filtrer",
    App_filterByKey: "Filtrer par clé",
    App_findItHere: "Retrouvez le ici",
    App_findItInDashboard: "Retrouvez le dans le tableau",
    App_follow: "Suivre",
    App_followed: "Suivi",
    App_format: "Format",
    App_getInTouch: "Ecrivez-nous",
    App_getStarted: "Démarrez en un clic",
    App_go: "Chercher",
    App_HOME: "ACCUEIL",
    App_id: "Identifiant",
    App_image: "Image",
    App_key: "Clé",
    App_keyHelper: "Seulement des lettres, chiffres, - et _",
    App_language: "Langue",
    App_language_english: "Anglais",
    App_language_french: "Français",
    App_licence: "Licence",
    App_load: "Charger fichier",
    App_manage: "Gérer",
    App_manageSerie: "Modifier l'indicateur",
    App_manageCollection: "Gérer la collection",
    App_MEMBERS: "MEMBRES",
    App_members: "Membres",
    App_membershipsCollections: "Droits d'accès à des collections",
    App_membershipsNoAccount: "Vous n'avez pas encore d'accès à un compte",
    App_membershipsAccounts: "Droits d'accès à des comptes",
    App_metricHasBeenSaved: "L'indicateur a bien été enregistré",
    App_MY_ALERTS: "MES ALERTES",
    App_MY_DASHBOARDS: "MES TABLEAUX DE BORD",
    App_SERIES: "INDICATEURS",
    App_SERIE: "INDICATEUR",
    App_series: "Indicateurs",
    App_seriesRemoved100: "100 indicateurs ont été effacés. Il reste d'autres indicateurs dans la collection",
    App_seriesRemovedAll: "Tous les indicateurs ont été effacés",
    App_seriesBeingRemoved: "Suppression ...",
    App_name: "Nom",
    App_next: "Suivant",
    App_no: "Non",
    App_observations: "Observations",
    App_OK: "OK",
    App_optional: "Optionnel",
    App_password: "Mot de passe",
    App_passwordYour: "Votre mot de passe",
    App_passwordHelper: "Au minimum 8 charactères",
    App_period: "Période",
    App_periods: "Périodes",
    App_post: "Question",
    App_platform: "Plateforme",
    App_pricing: "Tarifs",
    App_private: "Privé",
    App_PRIVATE: "PRIVÉ",
    App_PUBLIC: "PUBLIC",
    App_public: "Public",
    App_publicSeries: "Indicateurs publics",
    App_priority: "Priorité",
    App_priorityHelper: "Le graphique avec la priorité la plus haute sera affiché en premier",
    App_qas: "Questions & réponses",
    App_QUESTIONS: "QUESTIONS",
    App_question: "Question",
    App_quit: "Quitter",
    App_remove: "Supprimer",
    App_removeAlert: "Supprimer l'alerte",
    App_role: "Rôle",
    App_role_administrator: "Administrateur",
    App_role_editor: "Editeur",
    App_role_member: "Membre",
    App_role_owner: "Propriétaire",
    App_required: "Requis",
    App_save: "Enregistrer",
    App_search: "Chercher",
    App_searchStatit: "Chercher sur Statit",
    App_searchPublicMetrics: "Chercher des indicateurs publics",
    App_searchPublicMetricsHelper: "Température, savoie, covid ...",
    App_showMore: "Plus de paramètres",
    App_showUnit: "Montrer l'unité",
    App_signin: "Se connecter",
    App_signout: "Se déconnecter",
    App_signup: "Créer un compte",
    App_source: "Source",
    App_sources: "Sources",
    App_sub: "Abonnement",
    App_summary: "Résumé",
    App_template_basic: "Indicateurs simples",
    App_template_environment: "Indicateurs environnementaux",
    App_template_inventory: "Indicateurs de stocks",
    App_template_management: "Indicateurs de management",
    App_template_market: "Indicateurs de marché",
    App_template_products: "Indicateurs sur produits",
    App_type: "Type",
    App_tags: "Tags",
    App_time: "Date",
    App_userStatus: "Statut",
    App_userStatus_live: "Actif",
    App_userStatus_reset: "Reset requis",
    App_userStatus_unconfirmed: "Non confirmé",
    App_userStatus_dead: "Supprimé",
    App_unconfirmed: "Pas confirmé",
    App_update: "Modifier",
    App_updated: "Mis à jour",
    App_username: "Nom d'utilisateur",
    App_usernameYour: "Votre nom d'utilisateur",
    App_usernameHelper: "Seuls les lettres, chiffres et tirets (-, _) sont possibles. Merci",
    App_value: "Valeur",
    App_visitPublicSpaces: "Visiter les indicateurs publics",
    App_myAccounts: "Mes comptes",
    App_viewPublisher: "Editeur",
    App_viewUser: "Utilisateur",
    App_viewSwitchToDefault: "Aller à la vue normale",
    App_viewSwitchToExpert: "Aller à la vue expert",
    App_yes: "Oui",
    App_yourEmailAddress: "Votre email",
    App_yourPassword: "Votre mot de passe",
    AssetSubs_none: <>Vous n'avez pas encore d'indicateur favori. Visitez des indicateurs publics <Link to="/public" style={{ color: "inherit" }}>ici</Link></>,
    Button_addChartToDashboard: "Enregistrer",
    Button_addCollection: "Ajouter une collection",
    Button_addDashboard: "Ajouter un tableau",
    Button_addSerie: "Ajouter un indicateur",
    Button_addSeries: "Ajouter des indicateurs",
    Button_addMember: "Ajouter un membre",
    Button_addPost: "Nouvelle question",
    ChartPlot_noDataToDisplay: "Il n'y a pas encore d'observations ici",
    Charts_none: "Il n'y a pas encore de graphique ici",
    Collections_none: "Il n'y a pas encore de collection ici",
    DashboardChart_error: "Le graphique n'a pas pu être affiché",
    ChartLines_noData: "Il n'y a pas encore d'observations ici",
    ChartLines_errorObservations: "Il y a une erreur avec les observations du graphique",
    ChartLines_errorConfiguration: "Il y a une erreur avec la configuration du graphique",
    Dashboards_none: "Il n'y a pas encore de tableau ici",
    Dashboards_addSerie: "Ajouter",
    DashboardsHome_none: "Vous retrouverez ici vos tableaux personnels",
    DashboardsPublic_none: "Il n'y a pas de résultat pour ce terme",
    DashboardSubs_none: <>Vous n'avez pas encore de tableau favori. Visitez des tableaux publics  <Link to="/public" style={{ color: "inherit" }}>ici</Link></>,
    Drop_addChart: "Ajouter un graphique",
    Drop_addDashboard: "Ajouter un tableau",
    Drop_addSerie: "Ajouter indicateur",
    Drop_addSeries: "Ajouter des indicateurs",
    Drop_updateCollection: "Modifier la collection",
    Drop_deleteCollection: "Supprimer la collection",
    Drop_deleteSerie: "Supprimer l'indicateur",
    Drop_deleteSeries: "Supprimer les indicateurs",
    Drop_deleteChart: "Supprimer le graphique",
    Drop_deleteDashboard: "Supprimer le tableau",
    Drop_manageCollectionMembers: "Gérer les membres de la collection",
    Drop_updateChart: "Modifier le graphique",
    Drop_updateDashboard: "Modifier le tableau",
    Drop_updateSerie: "Modifier l'indicateur",
    Filter_noResult: "Désolé, il n'y a pas de résultat",
    FormChart_add: "AJOUT GRAPHIQUE",
    FormChart_addFromAsset:  "ENREGISTRER",
    FormChart_errorConfig: "La configuration ne peut pas être lue...",
    FormChart_formatHelper: "Un graphique en valeur représente une seule valeur, un graphique en ligne plusieurs valeurs dans le temps.",
    FormChart_update:  "METTRE A JOUR GRAPHIQUE",
    FormChartDefault_addSerie:  "Ajouter un indicateur",
    FormChartDefault_dateHelper: "Format: AAAA-MM-JJ",
    FormChartDefault_legend: "Légende",
    FormChartDefault_legendHelper: "Le nom qui sera indiqué pour l'indicateur sous le graphique",
    FormChartDefault_lineStyle: "Style ligne",
    FormChartDefault_lineWidth: "Largeur ligne (1 à 5)",
    FormChartDefault_stack: "Empiler",
    FormChartDefault_transformationParamHelper: "Pour les changements, le nombre de période(s) précédente avec laquelle comparer la valeur. Par exemple, 1 permet de comparer avec la période précédente. Pour les moyennes et sommes mobiles, le nombre de périodes à prendre en compte. Par exemple, 6 permet de réaliser l'opération sur 6 périodes",
    FormChartDefault_updateSerie:  "Modifier l'indicateur",
    FormChartFromAsset_dashboardExisting: "Choisissez un tableau existant",
    FormChartFromAsset_createNew: "Créez un nouveau tableau",
    FormChartFromAsset_errorKeyMissing: "Choisissez un identifiant pour votre tableau",
    FormChartFromAsset_errorKeyExists: "Cette clé existe déjà. Pouvez-vous en choisir une autre",
    FormChartFromAsset_pickDashboard: "Nom",
    FormChartFromChart_newBoard: "Nouveau tableau",
    FormChartFromChart_newBoardName: "Choisissez un nom",
    FormCollection_access_helper1: "Public: Tout le monde sur Internet pourra accéder",
    FormCollection_access_helper2: "Compte: Tous les membres du compte pourront accéder",
    FormCollection_access_helper3: "Privé: Tous les membres autorisés pourront accéder",
    FormCollection_access_helperSubRequired: <a href='mailto:hi@gostatit.com?subject=Abonnement' style={{color: "#ddd", textDecoration: "underline"}}>Abonnement requis</a>,
    FormCollection_add:  "AJOUTER UNE COLLECTION",
    FormCollection_chartsHelper:  "Commencez sans graphiques. Vous pourrez changer cela plus tard",
    FormCollection_descriptionHelper:  "Décrivez la collection ... Commencez avec une phrase",
    FormCollection_hasQas:"Oui",
    FormCollection_hasQasNo:"Non",
    FormCollection_keyCharactersStartsError:  "La clé de la collection ne peut commencer par les caractères - et _",
    FormCollection_keyHelper:  "C'est l'identifiant de la collection, choisisez le avec un nom simple et explicite. Seuls les lettres, les chiffres et  -, _, . peuvent être utilisés",
    FormCollection_keyExistsError:  "Cette clé existe déjà. Modifiez le tableau existant ou utilisez une autre clé",
    FormCollection_keyFormatError:  "La clé ne peut contenir que des lettres, des chiffres et les caractères suivants: -, _ et .",
    FormCollection_keyInvalidError:  "Cette clé n'est pas valide (mot clé réservé)",
    FormCollection_keyUnavailableError:  "Cette clé de collection n'est pas disponible",
    FormCollection_memberUsername:  "Nom d'utilisateur du membre",
    FormCollection_memberAdd:  "AJOUTER UN MEMBRE",
    FormCollection_memberUpdate:  "MODIFIER UN ACCÈS",
    FormCollection_qasHelper:  "Ajouter un espace de questions et réponses",
    FormCollection_remove:  "SUPPRIMER UNE COLLECTION",
    FormCollection_summaryHelper: "Décrivez la collection en une seule phrase",
    FormCollection_update:  "MODIFIER UNE COLLECTION",
    FormCollectionMember_add:  "AJOUTER UN MEMBRE",
    FormCollectionMember_update:  "MODIFIER UN ACCÈS",
    FormCollectionMember_errorAddingOwner: "Vous ne pouvez pas vous ajouter en tant que membre",
    FormCollectionMember_errorMemberExists: "Cet utilisateur est déjà un membre",
    FormCollectionMember_errorUserNotAccountMember: "Cet utilisateur ne peut pas être ajouté car il n'est pas membre de votre compte. Invitez le d'abord dans le compte et ajoutez le ensuite",
    FormCollectionMember_roleHelper: "Les membres peuvent accéder à la collection, les éditeurs peuvent éditer des indicateurs, les administrateurs peuvent ajouter des membres",
    FormCollectionPost_add:  "POSER UNE NOUVELLE QUESTION",
    FormCollectionPost_placeholder: "Poser votre question pour le ou la propriétaire de la collection",
    FormCollectionPost_placeholderComment: "Ajouter une réponse",
    FormDashboard_add:  "AJOUTER UN TABLEAU",
    FormDashboard_keyHelper:  "Les clés peuvent contenir des lettres, des chiffres, points (.), tirets (-) et tirets bas (_)",
    FormDashboard_keyFormatError:  "La clé ne peut contenir que des lettres, des chiffres et les caractères suivants: -, _ et .",
    FormDashboard_keyInvalidError:  "Cette clé n'est pas valide (mot clé réservé)",
    FormDashboard_keyExistsError:  "Cette clé existe déjà. Modifiez le tableau existant ou utilisez une autre clé",
    FormDashboard_keyUnavailableError:  "Cette clé de tableau n'est pas disponible",
    FormDashboard_keyCharactersStartsError:  "La clé du tableau ne peut commencer par les caractères - et _",
    FormDashboard_memberUsername:  "Nom d'utilisateur du membre",
    FormDashboard_memberAdd:  "AJOUTER UN MEMBRE",
    FormDashboard_memberUpdate:  "MODIFIER L'ACCÈS",
    FormDashboard_remove:  "SUPPRIMER TABLEAU",
    FormDashboard_tagsHelper: "Ajouter les tags en les séparant par ;",
    FormDashboard_update:  "MODIFIER UN TABLEAU",
    FormEmail_title: "Laissez-nous votre mail et nous revenons vers vous immédiatement",
    FormSerie_add:  "AJOUTER UNE SÈRIE",
    FormSerie_errorSerieSize:  "L'indicateur doit faire une taille maximale de 250KB. Réduisez le nombre d'observations",
    FormSerie_errorSaving:  "Nous ne parvenons pas à sauver cet indicateur",
    FormSerie_errorPathFormat:  "La clé ne peut contenir que des lettres, des chiffres et les caractères suivants: -, _, . et /",
    FormSerie_errorObservationsLineFormat: "Une des lignes ne peut pas être lue. Pouvez-vous vous assurer que chaque ligne ne contient qu'une date et une valeur (signe décimal comme point) séparés par ',', ';' ou tab",
    FormSerie_errorObservationsDate: "Une des dates ne peut pas être lue. Merci de vous assurer qu'elle suit le format ISO: AAAA-MM-JJ",
    FormSerie_errorObservationsDateMonthly: "Au moins une date ne correspond pas au format requis pour les indicateurs mensuels: AAAA-MM-01 or AAAA-MM",
    FormSerie_errorObservationsDateYearly: "Au moins une date ne correspond pas au format requis pour les indicateurs annuels: AAAA-MM-01 ou AAAA-MM ou AAAA",
    FormSerie_errorObservationsDateUnique: "Deux dates apparaissent similaires. Merci de vous assurer que les dates sont uniques.",
    FormSerie_errorAssetKeyExists: "Cette clé existe déjà. Vous pouvez la modifier ou en créer une autre",
    FormSerie_errorMaxDepth: "Vous avez atteint le nombre maximal de / pour la clé",
    FormSerie_keyHelper:  "Seulement des lettres, des chiffres et les caractères ., -, _ et /",
    FormSerie_loadFile: "Chargez un fichier",
    FormSerie_observationsHelper: "Chaque ligne a une observation. Une observation contient une date (format: AAAA-MM-JJ), une valeur (séparateur décimal: point) et éventuellement une métadonnée texte. Tous sont séparés par une virgule ou un point virgule.",
    FormSerie_observationsWrite: "... ou éditez les observations directement ici",
    FormSerie_update:  "MODIFIER UN INDICATEUR",
    FormSeriesFile_add:  "AJOUTER DES INDICATEURS DEPUIS UN FICHIER",
    FormSeriesFile_confirmTitle:  "Résultat de la validation",
    FormSeriesFile_confirmBody: "Les indicateurs ont été importés et vérifiés:",
    FormSeriesFile_confirmButton: "Publier les indicateurs",
    FormSeriesFile_dragAndDrop: "Déposez votre fichier Excel ou Csv directement ici",
    FormSeriesFile_dragAndInput: "ou cliquez là",
    FormSeriesFile_explanation: "Fichiers exemples:",
    FormSeriesFile_errorBuildingIndividualSeries: "Il y a un problème dans la construction des indicateurs",
    FormSeriesFile_errorFirstField: "Il y a un problème avec un des premiers champs de ligne ou colonne",
    FormSeriesFile_errorFormat: "Il y a un problème avec le format",
    FormSeriesFile_errorFrequencyLineMissing: "La ligne des fréquences est manquante (commençant par frequency)",
    FormSeriesFile_errorIDs: "Un des identifiants des indicateurs est incorrect. Vérifiez que chaque identifiant correspond bien à la collection",
    FormSeriesFile_errorIDLineMissing: "La ligne d'identifiant est manquante (commençant par id)",
    FormSeriesFile_seriesOK: "Indicateurs valides",
    FormSeriesFile_seriesNotOK: "Indicateurs non valides (Elles ne seront pas chargées)",
    FormSeriesFile_savingTitle: "Chargement",
    FormUser_basic_info: "Informations principales",
    FormUser_configuration: "Configuration",
    FormUser_configurationIntroUserAccount: "Votre compte est configuré pour la consultation d'indicateurs. Si vous souhaitez partager des indicateurs, transformez-le en compte 'Editeur' en cliquant ci-dessous",
    FormUser_configurationIntroPublisherAccount: "Votre compte est configuré pour la consultation et la publication d'indicateurs. Changez l'interface que vous souhaitez utiliser ci-dessous",
    FormUser_configurationPublicDisplayHelper: "Public: tout le monde sur Internet peut voir le compte (et les collections publiques). Privé: seul les membres du compte peuvent voir le compte",
    FormUser_configurationHelper: "La vue 'Utilisateur' vous permet de voir vos favoris, la vue 'Editeur' vous permet de publier des indicateurs",
    FormUser_confirm_title: "Supprimer le compte",
    FormUser_confirm_body: "Attention. Il n'y a pas de retour en arrière. Vous perdrez toutes vos données et abonnements",
    FormUser_confirm_instruction: "Entrez votre nom d'utilisateur pour confirmer la suppression",
    FormUser_confirm_delete_account: "Supprimer le compte",
    FormUser_danger_zone: "Zone de danger",
    FormUser_delete_account: "Supprimer le compte",
    FormUser_error_username: "Votre nom d'utilisateur est incorrect",
    FormUser_key: "Clé",
    FormUser_keyHelper: "La clé vous servira pour accéder aux indicateurs directement depuis Excel par exemple",
    FormUser_language: "Langue",
    FormUser_picture: "Image",
    FormUser_pictureUpload: "Charger",
    FormUser_pictureErrorNone: "Il n'y a pas encore d'image ici",
    FormUser_pictureErrorFormat: "Chargez une image au format PNG s'il vous plait",
    FormUser_pictureErrorSize: "Assurez vous que l'image est plus petite que 150 KB s'il vous plait",
    FormUser_publicDisplay: "Visibilité du compte",
    FormUser_transformAccount: "Transformer compte",
    FormUser_transformAccountHelper: "Un compte 'Editeur' vous permet de partager des indicateurs avec d'autres. Vous conservez vos favoris et tableaux de bord.",
    FormUser_view: "Vue",
    FormAccountMember_add:  "AJOUTER UN MEMBRE",
    FormAccountMember_update:  "MODIFIER UN ACCÈS",
    FormAccountMember_errorAddingOwner: "Vous ne pouvez pas vous ajouter en tant que membre",
    FormAccountMember_errorMemberExists: "Cet utilisateur est déjà un membre",
    FormAccountMember_roleHelper: "Les membres peuvent accéder au compte. Les administrateurs peuvent également ajouter des membres",
    GetCollection_serieNone: "Il n'y a pas encore d'indicateur ici",
    GetCollection_descriptionNone: "Il n'y a encore de description ...",
    GetCollection_author: "Auteur",
    ItemSubs_none: <>Vous n'avez pas encore de graphique favori. Visitez des graphiques publics <Link to="/public" style={{ color: "inherit" }}>ici</Link></>,
    Lang: "fr",
    Licence_cc0: "Domaine public",
    Licence_ccby: "Attribution",
    Licence_ccbysa: "Attribution - Partage dans les mêmes conditions",
    Licence_ccbynd: "Attribution - Pas de modification",
    Licence_ccbync: "Attribution - Pas d’utilisation commerciale",
    Licence_ccbyncsa: "Attribution - Pas d’Utilisation Commerciale - Partage dans les Mêmes Conditions",
    Licence_ccbyncnd: "Attribution - Pas d’Utilisation Commerciale - Pas de Modification",
    ListCollectionMembers_title: "Membres de la collection",
    MadeWithLoveOnStatit: "Statit (www.gostatit.com)",
    Members_none: "Il n'y a pas de membre pour le moment",
    Serie_agg: "Agrégation",
    Serie_agg_avg: "Moyenne",
    Serie_agg_eop: "Fin de la période",
    Serie_agg_no: "Pas d'agrégation",
    Serie_agg_sum: "Somme",
    Serie_chg_: "Aucune",
    Serie_chg_no: "Pas de transformation",
    Serie_chg_per: "Changement versus période précédente",
    Serie_chg_perpc: "Changement versus période précédente (%)",
    Serie_chg_avg: "Moyenne mobile dernière(s) périodes",
    Serie_chg_last: "Somme mobile dernière(s) périodes",
    Serie_chg_index: "Indice",
    Serie_conditionColorGreaterThanColor: "Couleur",
    Serie_conditionColorGreaterThanValue: "Valeur (supérieur à)",
    Serie_conditionColorLowerThanColor: "Couleur",
    Serie_conditionColorLowerThanValue: "Valeur (inférieur à)",
    Serie_dateStart: "Début",
    Serie_dateEnd: "Fin",
    Serie_freq: "Fréquence",
    Serie_freq_C: "Catégorie",
    Serie_freq_D: "Quotidien",
    Serie_freq_W: "Hebdomadaire",
    Serie_freq_M:  "Mensuel",
    Serie_freq_Q: "Trimestriel",
    Serie_freq_S: "Semestriel",
    Serie_freq_Y: "Annuel",
    Serie_freq_none: "Aucune",
    Serie_lineStackGroup: "Oui",
    Serie_lineStackGroupPercent: "Oui (100%)",
    Serie_lineStackNoGroup: "Non",
    Serie_lineStyleDash: "Tiret",
    Serie_lineStyleDashDot: "Tiret point",
    Serie_lineStyleDot: "Point",
    Serie_lineStyleLine: "Ligne",
    Serie_method: "Méthode",
    Serie_noObservations: "Il n'y a pas d'observation à afficher pour le moment",
    Serie_notes:  "Notes",
    Serie_period: "Période",
    Serie_seasonStartDay: "Jour de début d'année",
    Serie_seasonNumYears: "Nombre d'années",
    Serie_seasonColors: "Couleurs",
    Serie_transformation: "Transformation",
    Serie_transformationParam: "Paramètre de la transformation",
    Serie_unit: "Unité",
    Serie_xaxisDate: "Date",
    Serie_xaxisCategory: "Catégorie",
    Serie_xaxisFormat: "Format de l'axe horizontal",
    Serie_yaxis_0: "100",
    Serie_yaxis_0_0: "100.0",
    Serie_yaxis_0_00: "100.00",
    Serie_yaxis_0pc: "100%",
    Serie_yaxis_0_0pc: "100.0%",
    Serie_yaxis_0_00pc: "100.00%",
    Serie_yaxisFormat: "Format valeur(s)",
    Serie_yaxis_rangeMode: "Alignement vertical",
    Serie_yaxis_rangeMode_None: "Alignement autour des valeurs",
    Serie_yaxis_rangeMode_toZero: "Alignement sur zero",
    NavBottom_public: "Public",
    NavBottom_privacy: "Vie privée",
    NavTop_home: "Accueil",
    NavTop_publisherguide: "Guide Éditeur",
    NavTop_userguide: "Guide Utilisateur",
    NavTop_account: "Mon compte",
    NotFound_text: "Cette page n'existe pas ou est peut-être privée",
    NotFound_signin: "Connectez-vous pour y accéder",
    NotSupported_error: "Statit ne fonctionne pas avec Internet Explorer. Pouvez-vous utiliser un autre navigateur?",
    PostComments_none: "Il n'y a pas encore de réponse",
    Posts_none: "Il n'y a pas encore de question",
    Sign_p1: "Remplissez votre email s'il vous plait",
    Sign_p1_sub: "Entrez votre email pour recevoir un message lors de la mise à jour",
    Sign_p2_sub: "Vous pourrez ensuite facilement annuler cela si vous le souhaitez",
    Sign_p2: "Et maintenant votre mot de passe",
    Sign_p3: "Finalisez la création de votre compte",
    Sign_p4: "Bienvenue sur Statit 😀",
    Sign_p4_2: "S'il vous plait, choisissez un nom d'utilisateur et un mot de passe",
    Sign_email_invalid: "Excusez nous, cet email ne semble pas correct",
    Sign_userNotFound: "Désole, cet utilisateur ne peut pas être trouvé. Pouvez-vous ré-essayer?",
    Sign_unauthorised: "Nous ne reconnaissons pas ce mot de passe pour ce compte. Voulez-vous le ré-initialiser?",
    Sign_link_reset:"Mot de passe perdu?",
    Sign_link_signin: "Vous avez déjà un compte?",
    Sign_link_signup: "Pas de compte?",
    Sign_reset_title: "Entrez votre mot de passe",
    Sign_reset_p1: "S'il vous plait insérez le code que nous vous avons envoyé par mail",
    SignResetComp_confirmation_code: "Code de confirmation",
    SignResetComp_instruction: "Nous vous avons envoyé un code de confirmation par email",
    SignResetComp_new_password:  "Nouveau mot de passe",
    SignResetComp_send: "Envoyer",
    SignUp_addConfirmatonCodeAndPassword: "Remplissez le code de confirmation reçu et choisissez un mot de passe",
    SignUp_addUsername: "Choisissez un nom d'utilisateur",
    SignUp_confirmation: "Code de confirmation",
    SignUp_errorUsernameCharacters: "Désolé, le nom d'utilisateur peut seulement contenir des lettres et des chiffres",
    SignUp_errorUsernameUnder3: "Désolé, le nom d'utilisateur doit au moins avoir 3 lettres",
    SignUp_errorUsernameUnavailable: "Désolé, ce nom d'utilisateur n'est pas disponible",
    SignUpAutoComp_wait: "Attendez quelques secondes s'il vous plait ...",
    SignUpAutoComp_done: "Merci, votre favori a été créé. Vous pouvez vous connecter avec votre email pour le retrouver"
  },
  en: {
    Action_errorRemoveAccountOwner: "Please, only the account owner can delete the account",
    Action_errorRemoveAccountCollections: "Please remove all collections before deleting the account",
    Action_errorRemoveAccountDashboards: "Please remove all dashboards before deleting the account",
    Action_errorDeleteDashboards: "Please remove all dashboards before deleting the collection",
    Action_errorDeleteCollectionSeries: "Please remove all metrics before deleting the collection",
    Action_errorDeleteCollectionMembers: "Please remove all members before deleting the collection",
    Action_errorDeleteCollectionMemberOwner: "The owner of the collection can not remove its membership",
    Action_errorDeleteCollectionOwner: "Please, only the owner can remove a collection",
    Action_errorDeleteParentSeries: "Please remove all metrics before deleting this parent",
    Action_errorRemoveDashboardCharts: "Please remove all charts before deleting the dashboard",
    Alert_add: "Add",
    Alert_never: "No alert",
    Alert_onUpdateDaily: "Alert end of day",
    Alert_onUpdateWeekly: "Alert once a week",
    Alert_onUpdateMonthly: "Alert once a month",
    Alert_onUpdateDailyThreshold: "Alert end of day (with threshold)",
    Alert_remove: "Remove",
    Alert_setAlertThreshold: "Set alert with threshold",
    Alert_setAlertThreshold_p1: "Receive an alert on update:",
    Alert_setAlertThreshold_p2: "If the metric value is above:",
    Alert_setAlertThreshold_p3: "or if it is below:",
    App_about: "A propos",
    App_access: "Access",
    App_ACCOUNT: "ACCOUNT",
    App_account: "Account",
    App_accounts: "Accounts",
    App_accountMain: "General",
    App_accountMemberships: "Access",
    App_accountPublisher: "Publisher - To publish and access metrics",
    App_accountType: "Account type",
    App_accountTypeHelper: "It is not a final choice, you will be able to change after",
    App_accountUser: "User - To access metrics",
    App_action: "Action",
    App_addDashboard: "Add dashboard",
    App_addDashboardVerbose: "Do you want to add the dashboard to your personal dashboards?",
    App_addToDashboard: "Add to a dashboard",
    App_ALERTS: "ALERTS",
    App_ANALYTICS: "ANALYTICS",
    App_analyticsActions: "Actions",
    App_analyticsActionsNone: "This collection has not been consulted yet",
    App_analyticsAssetSub: "Serie",
    App_analyticsDashboardSub: "Dashboard",
    App_analyticsSubscribtions: "Favorites",
    App_analyticsSubsNone: "There is no favourite for this collection yet",
    App_analyticsdownload: "Download",
    App_analyticsdownloadPng: "Download image",
    App_analyticsgetAsset: "Serie",
    App_analyticsgetCollection: "Collection",
    App_analyticsgetDashboard: "Dashboard",
    App_analyticsgetItem: "Chart",
    App_analyticsgetSerie: "Serie",
    App_appDescription: "Statit lets you organise your important metrics and share them simply with your team or community. Get started in minutes",
    App_appTitle: "Statit | Monitoring what counts",
    App_askedBy: "By",
    App_cancel: "Cancel",
    App_channel: "Channel",
    App_CHART: "CHART",
    App_chart: "Chart",
    App_chartHasBeenSaved: "The chart has been saved",
    App_chartBar: "Bar chart",
    App_chartLine: "Line chart",
    App_chartLineSeasonal: "Line chart - Seasonal",
    App_chartValue: "Value chart",
    App_CHARTS: "CHARTS",
    App_charts: "Charts",
    App_color: "Color",
    App_COLLECTION: "COLLECTION",
    App_collection: "Collection",
    App_collectionAccount: "Account",
    App_collectionPrivate: "Private",
    App_collectionPublic: "Public",
    App_COLLECTIONS: "COLLECTIONS",
    App_collections: "Collections",
    App_comments: "Comments",
    App_community: "Community",
    App_conditions: "Conditions",
    App_configuration: "Configuration",
    App_createAlert: "Add an alert",
    App_createNewDashboard: "Add a new dashboard",
    App_DASHBOARD: "DASHBOARD",
    App_dashboard: "Dashboard",
    App_dashboardHasBeenSaved: "The dashboard has been saved in your personal dashboards",
    App_dashboardPersonal: "Personal dashboard",
    App_DASHBOARDS: "DASHBOARDS",
    App_dashboards: "Dashboards",
    App_data: "Data",
    App_DATA: "DATA",
    App_DESCRIPTION: "DESCRIPTION",
    App_description: "Description",
    App_documentation: "User guide",
    App_download: "Download",
    App_downloadData: "Download metrics",
    App_downloadChart: "Download chart",
    App_edit: "Edit",
    App_email: "Email",
    App_errorBoundary: <>Sorry, an error occured. Please get in touch at <a href="mailto:hi@gostatit.com">hi@gostatit.com</a></>,
    App_errorDb: "Sorry, there has been an error",
    App_errorNetwork: "You do not seem to be online. Are you still connected?",
    App_file: "File",
    App_fileCsv: "Csv File",
    App_fileXlsx: "Excel File",
    App_filter: "Filter",
    App_filterByKey: "Filter by key",
    App_findItHere: "Find it here",
    App_findItInDashboard: "Find it in dashboard",
    App_follow: "Follow",
    App_followed: "Followed",
    App_FAVORITES: "FAVORITES",
    App_favorites: "Favorites",
    App_favorite: "Favorite",
    App_format: "Format",
    App_getInTouch: "Get in touch",
    App_getStarted: "Get started in a click",
    App_go: "Search",
    App_HOME: "HOME",
    App_id: "Identifier",
    App_image: "Picture",
    App_key: "Key",
    App_keyHelper: "Only letters, numbers, - and _",
    App_language: "Language",
    App_language_english: "English",
    App_language_french: "French",
    App_licence: "Licence",
    App_load: "Load file",
    App_manage: "Manage",
    App_manageSerie: "Manage metric",
    App_manageCollection: "Manage collection",
    App_MEMBERS: "MEMBERS",
    App_members: "Members",
    App_membershipsCollections: "Access to collections",
    App_membershipsNoAccount: "You don't have access to an account yet",
    App_membershipsAccounts: "Access to accounts",
    App_metricHasBeenSaved: "The metric has been saved",
    App_myDashboard: "My dashboard",
    App_MY_ALERTS: "MY ALERTS",
    App_MY_DASHBOARDS: "MY DASHBOARDS",
    App_SERIE: "METRIC",
    App_SERIES: "METRICS",
    App_series: "Metrics",
    App_seriesRemoved100: "Hundred metrics have been removed. There are still metrics in the collection",
    App_seriesRemovedAll: "All metrics have been removed",
    App_seriesBeingRemoved: "Removal ...",
    App_statistics: "Metrics",
    App_name: "Name",
    App_next: "Next",
    App_no: "No",
    App_observations: "Observations",
    App_OK: "OK",
    App_password: "Password",
    App_passwordYour: "Your password",
    App_passwordHelper: "At least 8 characters",
    App_period: "Period",
    App_periods: "Periods",
    App_priority: "Priority",
    App_priorityHelper: "The chart with the highest number will be displayed first",
    App_private: "Private",
    App_PRIVATE: "PRIVATE",
    App_PUBLIC: "PUBLIC",
    App_public: "Public",
    App_publicSeries: "Public metrics",
    App_qas: "Questions & answers",
    App_question: "Question",
    App_QUESTIONS: "QUESTIONS",
    App_quit: "Exit",
    App_role: "Role",
    App_role_administrator: "Administrator",
    App_role_editor: "Editor",
    App_role_member: "Member",
    App_role_owner: "Owner",
    App_optional: "Optional",
    App_platform: "Platform",
    App_pricing: "Pricing",
    App_post: "Question",
    App_remove: "Remove",
    App_removeAlert: "Remove the alert",
    App_required: "Required",
    App_save: "Save",
    App_search: "Search",
    App_searchStatit: "Search Statit",
    App_searchPublicMetrics: "Search public metrics",
    App_searchPublicMetricsHelper: "France, world, covid ...",
    App_showMore: "More parameters",
    App_showUnit: "Show unit",
    App_signin: "Sign in",
    App_signout: "Sign out",
    App_signup: "Sign up",
    App_tags: "Tags",
    App_time: "Date",
    App_source: "Source",
    App_sources: "Sources",
    App_sub: "Subscription",
    App_summary: "Summary",
    App_template_basic: "Simple metrics",
    App_template_environment: "Environnemental metrics",
    App_template_inventory: "Inventory metrics",
    App_template_management: "Management metrics",
    App_template_market: "Market metrics",
    App_template_products: "Product metrics",
    App_type: "Type",
    App_unconfirmed: "Unconfirmed",
    App_update: "Update",
    App_updated: "Updated",
    App_username: "Username",
    App_usernameHelper: "Please, only use characters such as letters, numbers and dashes (-, _)",
    App_usernameYour: "Your username",
    App_userStatus: "Status",
    App_userStatus_live: "Active",
    App_userStatus_reset: "Reset required",
    App_userStatus_unconfirmed: "Not confirmed",
    App_userStatus_dead: "Supressed",
    App_value: "Value",
    App_visitPublicSpaces: "Visit public metrics",
    App_myAccounts: "My accounts",
    App_viewPublisher: "Publisher",
    App_viewUser: "User",
    App_viewSwitchToDefault: "Switch to normal view",
    App_viewSwitchToExpert: "Switch to expert view",
    App_yes: "Yes",
    App_yourEmailAddress: "Your email",
    App_yourPassword: "Your password",
    AssetSubs_none: <>You have no favorite metric yet. Visit public metrics <Link to="/public" style={{ color: "inherit" }}>here</Link></>,
    Button_addChartToDashboard: "Save",
    Button_addCollection: "Add a collection",
    Button_addDashboard: "Add a dashboard",
    Button_addSerie: "Add a metric",
    Button_addSeries: "Add multiple metrics",
    Button_addMember: "Add member",
    Button_addPost: "New question",
    Charts_none: "There is no chart at the moment",
    ChartPlot_noDataToDisplay: "There are no observations for the moment",
    Collections_none: "There is no collection yet",
    DashboardChart_error: "The chart can not be displayed",
    ChartLines_noData: "There are no observations",
    ChartLines_errorObservations: "There is an error with observations in the chart",
    ChartLines_errorConfiguration: "There is an error with the configuration",
    DashboardSubs_none: <>You have no favorite dashboard yet. Visit public dashboards <Link to="/public" style={{ color: "inherit"}}>here</Link></>,
    Dashboards_addSerie: "Add",
    Dashboards_none: "There is no dashboard at the moment",
    DashboardsHome_none: "You will find here your personal dashboards",
    DashboardsPublic_none: "There is no result for this term",
    Drop_addSerie: "Add a metric",
    Drop_addSeries: "Add multiple metrics",
    Drop_addChart: "Add a chart",
    Drop_addDashboard: "Add a dashboard",
    Drop_deleteChart: "Delete chart",
    Drop_deleteCollection: "Remove collection",
    Drop_deleteSerie: "Delete metric",
    Drop_deleteSeries: "Delete metrics",
    Drop_deleteDashboard: "Delete dashboard",
    Drop_manageCollectionMembers: "Manage collection members",
    Drop_updateSerie: "Update metric",
    Drop_updateCollection: "Update collection",
    Drop_updateChart: "Update chart",
    Drop_updateDashboard: "Update dashboard",
    Filter_noResult: "Sorry, there is no result for this search",
    FormChart_add: "ADD A CHART",
    FormChart_addFromAsset:  "SAVE",
    FormChart_errorConfig: "The configuration can not be read. Please review it",
    FormChart_formatHelper: "A value chart shows a single value, a line chart will display multiple values over time.",
    FormChart_update:  "UPDATE CHART",
    FormChartFromAsset_dashboardExisting: "Pick an existing dashboard",
    FormChartFromAsset_pickDashboard: "Dashboards",
    FormChartFromAsset_createNew: "Add a dashboard",
    FormChartFromAsset_errorKeyMissing: "Pick, select a key for your dashboard",
    FormChartFromChart_newBoard: "New Dashboard",
    FormChartFromChart_newBoardName: "Select a name",
    FormChartDefault_addSerie:  "Add a metric",
    FormChartDefault_dateHelper: "Format: YYYY-MM-DD",
    FormChartDefault_legend: "Legend",
    FormChartDefault_legendHelper: "The legend will be displayed under the chart",
    FormChartDefault_lineStyle: "Line style",
    FormChartDefault_lineWidth: "Line width (1 to 5)",
    FormChartDefault_stack: "Stack",
    FormChartDefault_transformationParamHelper: "For changes, the number of preceding period(s) against which comparing the last value. For instance, 1 will provide comparison against the previous period. For average and sum, the number of periods to take into account. For instance, 6 will make the operation on 6 periods",
    FormChartDefault_updateSerie:  "Update metric",
    FormCollection_access_helper1: "Public: Everyone on the Internet will be able to access the metrics",
    FormCollection_access_helper2: "Account: All account members will be able to access",
    FormCollection_access_helper3: "Private: All authorised members will be able to access",
    FormCollection_access_helperSubRequired: <a href="mailto:hi@gostatit.com?subject=Subscription" style={{color: "black", textDecoration: "underline"}}>Subscription required</a>,
    FormCollection_add:  "ADD A COLLECTION",
    FormCollection_chartsHelper:  "Start with no charts. You will be able to change that later",
    FormCollection_descriptionHelper:  "Describe the collection ... Start with a sentence",
    FormCollection_hasQas:"Yes",
    FormCollection_hasQasNo:"No",
    FormCollection_keyCharactersStartsError:  "The collection key can not start with - or  _",
    FormCollection_keyExistsError:  "This key exists already. Update the existing collection or pick a new one",
    FormCollection_keyFormatError:  "Sorry, the key can only contain letters, numbers, -, _ and .",
    FormCollection_keyHelper:  "It is the identifier for the collection, choose a simple and explicit key. Only letters, numbers, -, _ and . are possible",
    FormCollection_keyInvalidError:  "Sorry, this key is invalid",
    FormCollection_keyUnavailableError:  "This key is unavailable",
    FormCollection_memberUsername:  "Member username",
    FormCollection_memberAdd:  "ADD A MEMBER",
    FormCollection_memberUpdate:  "UPDATE ACCESS",
    FormCollection_qasHelper:  "Add a question & answer space",
    FormCollection_remove:  "REMOVE COLLECTION",
    FormCollection_summaryHelper: "Describe the collection in a single sentence",
    FormCollection_update:  "UPDATE COLLECTION",
    FormCollectionMember_add:  "ADD A MEMBER",
    FormCollectionMember_update:  "UPDATE MEMBERSHIP",
    FormCollectionMember_errorAddingOwner: "You can not add yourself as a member",
    FormCollectionMember_errorMemberExists: "This user is already a member",
    FormCollectionMember_errorUserNotAccountMember: "This user can not be added because it is not a member of the account. Add it first to the account and then add it to the collection",
    FormCollectionMember_roleHelper: "Members can access metrics. Editors can edit metrics. Administrators can add and manage members",
    FormCollectionPost_add:  "ASK NEW QUESTION",
    FormCollectionPost_placeholder: "Add your question for the owner of the collection",
    FormCollectionPost_placeholderComment: "Add a response",
    FormChartFromAsset_errorKeyExists: "This key exists already. Please, pick another one",
    FormDashboard_add:  "ADD A DASHBOARD",
    FormDashboard_keyHelper:  "Keys may contain letters, numbers, ., - and _",
    FormDashboard_keyFormatError:  "Sorry, the key can only contain letters, figures, ., - and _",
    FormDashboard_keyInvalidError:  "Sorry, this key is a reserved keyword",
    FormDashboard_keyExistsError:  "Sorry, this key exists already. Modify the current dashboard or pick a new one",
    FormDashboard_keyCharactersStartsError:  "The key can not start with the characters - and _",
    FormDashboard_keyUnavailableError:  "This key is already in use. Please pick a different one",
    FormDashboard_memberUsername:  "Member username",
    FormDashboard_memberAdd:  "ADD A MEMBER",
    FormDashboard_memberUpdate:  "UPDATE ACCESS",
    FormDashboard_remove:  "REMOVE DASHBOARD",
    FormDashboard_tagsHelper: "Add tags and separate them with ;",
    FormDashboard_update:  "UPDATE DASHBOARD",
    FormEmail_title: "Please, leave us your email and will get back to you",
    FormSerie_add:  "ADD A METRIC",
    FormSerie_errorSerieSize:  "The maximal size for a metric is 250KB. Please reduce the number of observations",
    FormSerie_errorSaving:  "We can't save this metric",
    FormSerie_errorPathFormat:  "Sorry, the key can only contain letters, numbers, ., -, _ and /",
    FormSerie_errorObservationsLineFormat: "One of the lines can not be read. Please make sure all lines contain only one date and one value (decimal sign as a point) separated by ',', ';' or tab",
    FormSerie_errorObservationsDate: "One of the dates can not be read. Please follow the format: YYYY-MM-DD",
    FormSerie_errorObservationsDateMonthly: "At least, one of the dates does not match the required format for a monthly metric: YYYY-MM-01 or YYYY-MM",
    FormSerie_errorObservationsDateYearly: "At least, one of the dates does not match the required format for a yearly metric: YYYY-01-01 ou YYYY-01 or YYYY" ,
    FormSerie_errorObservationsDateUnique: "Two dates seem to be similar. Please make sure they are unique.",
    FormSerie_errorAssetKeyExists: "This key exists already. Please modifiy the existing one or create a new one",
    FormSerie_errorMaxDepth: "You have reached the maximum number of / for this key",
    FormSerie_keyHelper:  "Only letters, numbers and the characters: ., -, _ and /",
    FormSerie_loadFile: "Load a file",
    FormSerie_observationsHelper: "Every line should contain one observation. An observation is a date (format: YYYY-MM-DD), a value (point as decimal separator), separated by ',', ';' or tab",
    FormSerie_observationsWrite: "... or edit observations directly here",
    FormSerie_update:  "UPDATE METRIC",
    FormSeriesFile_add:  "ADD METRICS FROM FILE",
    FormSeriesFile_confirmTitle:  "Validation results",
    FormSeriesFile_confirmBody: "Series have been imported and checked:",
    FormSeriesFile_confirmButton: "Publish metrics",
    FormSeriesFile_dragAndDrop: "Drag and drop your Excel or CSV file directly here",
    FormSeriesFile_dragAndInput: "or click there",
    FormSeriesFile_errorBuildingIndividualSeries: "There is a problem building one of the metrics",
    FormSeriesFile_errorFirstField: "There is a problem with one of the first field in a column or line",
    FormSeriesFile_errorFormat: "There is a problem with the format",
    FormSeriesFile_errorFrequencyLineMissing: "The frequency line (starting with frequency) is missing",
    FormSeriesFile_errorIDs: "One of the identifier does not seem right. Please check each id belongs to the collection",
    FormSeriesFile_errorIDLineMissing: "The identifier line (starting with id) is missing",
    FormSeriesFile_explanation: "Template files:",
    FormSeriesFile_seriesOK: "Valid metrics",
    FormSeriesFile_seriesNotOK: "Invalid metrics (they will not be loaded)",
    FormSeriesFile_savingTitle: "Loading",
    FormUser_language: "Language",
    FormUser_basic_info: "Main info",
    FormUser_configuration: "Configuration",
    FormUser_configurationHelper: "The 'User' view lets you access your favorites, the 'Publisher' view lets you publish metrics",
    FormUser_confirm_title: "Delete account",
    FormUser_configurationIntroUserAccount: "Your account is a 'User' account to access metrics. If you would like to share metrics, transform it into a 'Publisher' account by clicking below",
    FormUser_configurationIntroPublisherAccount: "Your account is configured to access and publish metrics. Change your user interface below",
    FormUser_configurationPublicDisplayHelper: "Public: everyone on the Internet can see the account (and the public collections). Private: only members of the account can see it",
    FormUser_confirm_body: "Be careful. This can not be undone. You will lose all collections, metrics and subscriptions",
    FormUser_confirm_instruction: "Enter your username to delete your account",
    FormUser_confirm_delete_account: "Delete account",
    FormUser_danger_zone: "Danger zone",
    FormUser_delete_account: "Delete account",
    FormUser_error_username: "The username entered is incorrect",
    FormUser_key: "Key",
    FormUser_keyHelper: "The key will be used to access metrics directly from Excel for instance",
    FormUser_picture: "Picture",
    FormUser_pictureUpload: "Upload",
    FormUser_pictureErrorNone: "There is no picture for the moment",
    FormUser_pictureErrorFormat: "Please, only load an image with PNG format",
    FormUser_pictureErrorSize: "Please ensure the picture is smaller than 150 KB",
    FormUser_publicDisplay: "Account visibility",
    FormUser_transformAccount: "Transform account",
    FormUser_transformAccountHelper: "A 'Publisher' account lets you share metrics and invite collaborators. You will not lose your current favorites and dashboards",
    FormUser_view: "View",
    FormAccountMember_add:  "ADD A MEMBER",
    FormAccountMember_update:  "UPDATE MEMBERSHIP",
    FormAccountMember_errorAddingOwner: "You can not add yourself as a member",
    FormAccountMember_errorMemberExists: "This user is already a member",
    FormAccountMember_roleHelper: "Members can access metrics. Administrators can add and manage collections and members",
    GetCollection_serieNone: "There is no metric yet ...",
    GetCollection_descriptionNone: "There is no description yet ...",
    GetCollection_author: "Author",
    ItemSubs_none: <>You have no favorite chart yet. Visit public metrics <Link to="/public" style={{ color: "inherit" }}>here</Link></>,
    Lang: "en",
    Licence_cc0: "No Copyright",
    Licence_ccby: "Attribution",
    Licence_ccbysa: "Attribution - Share Alike",
    Licence_ccbynd: "Attribution - No Derivatives",
    Licence_ccbync: "Attribution - Non Commercial",
    Licence_ccbyncsa: "Attribution - Non Commercial - Share Alike ",
    Licence_ccbyncnd: "Attribution - Non Commercial - No Derivatives",
    ListCollectionMembers_title: "Collection members",
    MadeWithLoveOnStatit: "Statit (www.gostatit.com)",
    Members_none: "There is no member yet",
    Serie_agg: "Aggregation",
    Serie_agg_sum: "Sum",
    Serie_agg_avg: "Average",
    Serie_agg_eop: "End of period",
    Serie_agg_no: "No aggregation",
    Serie_chg_: "None",
    Serie_chg_no: "No transformation",
    Serie_chg_per: "Change versus precedent period",
    Serie_chg_perpc: "Change versus precedent period (%)",
    Serie_chg_avg: "Moving average last periods",
    Serie_chg_last: "Moving sum last périods",
    Serie_chg_index: "Index",
    Serie_conditionColorGreaterThanColor: "Color",
    Serie_conditionColorGreaterThanValue: "Value (greater than)",
    Serie_conditionColorLowerThanColor: "Color",
    Serie_conditionColorLowerThanValue: "Value (lower than)",
    Serie_dateStart: "Start" ,
    Serie_dateEnd: "End",
    Serie_freq: "Frequency",
    Serie_freq_C: "Category",
    Serie_freq_D: "Daily",
    Serie_freq_W: "Weekly",
    Serie_freq_M:  "Monthly",
    Serie_freq_Q: "Quarterly",
    Serie_freq_S: "Bi-annual",
    Serie_freq_Y: "Yearly",
    Serie_freq_none: "None",
    Serie_lineStackGroup: "Yes",
    Serie_lineStackGroupPercent: "Yes (100%)",
    Serie_lineStackNoGroup: "None",
    Serie_lineStyleDash: "Dash",
    Serie_lineStyleDashDot: "Dash dot",
    Serie_lineStyleDot: "Dot",
    Serie_lineStyleLine: "Line",
    Serie_method: "Method",
    Serie_noObservations: "There is no observation to display at the moment",
    Serie_notes: "Notes",
    Serie_period: "Period",
    Serie_seasonStartDay: "Start day for year",
    Serie_seasonNumYears: "Number of years",
    Serie_seasonColors: "Colors",
    Serie_transformation: "Transformation",
    Serie_transformationParam: "Transformation parameter",
    Serie_unit:  "Unit",
    Serie_xaxisDate: "Date",
    Serie_xaxisCategory: "Category",
    Serie_xaxisFormat: "Format of horizontal axis",
    Serie_yaxis_0: "100",
    Serie_yaxis_0_0: "100.0",
    Serie_yaxis_0_00: "100.00",
    Serie_yaxis_0pc: "100%",
    Serie_yaxis_0_0pc: "100.0%",
    Serie_yaxis_0_00pc: "100.00%",
    Serie_yaxisFormat: "Format value(s)",
    Serie_yaxis_rangeMode_None: "Alignment on values",
    Serie_yaxis_rangeMode_toZero: "Alignment on zero",
    Serie_yaxis_rangeMode: "Vertical alignment",
    NavBottom_public: "Public",
    NavBottom_privacy: "Privacy",
    NavTop_home: "Home",
    NavTop_publisherguide: "Publisher guide",
    NavTop_userguide: "User guide",
    NavTop_account: "My account",
    NotFound_text: "This page does not exist or might be private",
    NotFound_signin: "Sign-in to access it",
    NotSupported_error: "Statit does not work with Internet Explorer. Can you use a different browser?",
    PostComments_none: "There is no response yet",
    Posts_none: "There is no question yet",
    Sign_p1: "Please enter your email",
    Sign_p1_sub: "Enter your email to receive a message on update",
    Sign_p2_sub: "You will be able to cancel this easily if you wish",
    Sign_p2: "And now your password",
    Sign_p3: "Finalise the creation of your account",
    Sign_p4: "Welcome on Statit 😀",
    Sign_p4_2: "Please pick a username and password",
    Sign_email_invalid: "Sorry, this email seems invalid",
    Sign_unauthorised: "This password does not match our records. Would you like to reset it?",
    Sign_userNotFound: "Sorry, this user can not be found. Can you try again?",
    Sign_link_reset:"Password forgotten?",
    Sign_link_signin: "Do you already have an account?",
    Sign_link_signup: "No account?",
    Sign_reset_title: "Fill-in your password",
    Sign_reset_p1: "Please enter the code received by email",
    SignResetComp_send: "Send",
    SignResetComp_confirmation_code: "Confirmation code",
    SignResetComp_instruction: "We have sent you a confirmation code by email",
    SignResetComp_new_password: "New Password",
    SignUp_addUsername: "Pick a username",
    SignUp_addConfirmatonCodeAndPassword: "Fill-in the confirmation code received and choose a password",
    SignUp_confirmation: "Confirmation code",
    SignUp_errorUsernameCharacters: "Sorry, the username can only contain letters and numbers",
    SignUp_errorUsernameUnder3: "Sorry, the username must be at least 3 characters long",
    SignUp_errorUsernameUnavailable: "Sorry, this username is unavailable",
    SignUpAutoComp_wait: "Please wait a few seconds ...",
    SignUpAutoComp_done: "Thanks, your favorite has been saved. You can sign-in with your email to find it easily"
}
}

export const Licences = {
  cc0: "https://creativecommons.org/publicdomain/zero/1.0/",
  ccby: "https://creativecommons.org/licenses/by/4.0/",
  ccbysa: "https://creativecommons.org/licenses/by-sa/4.0/",
  ccbynd: "https://creativecommons.org/licenses/by-nd/4.0/",
  ccbync: "https://creativecommons.org/licenses/by-nc/4.0/",
  ccbyncsa: "https://creativecommons.org/licenses/by-nc-sa/4.0/",
  ccbyncnd: "https://creativecommons.org/licenses/by-nc-nd/4.0/",
}
